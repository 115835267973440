import $ from 'jquery';
import { get, set, remove } from "./storage";
import { getSubscription } from './swManager';
import { PaginationParams, UserI, SettingsI, ShippingI, RelayI } from './interfaces'
import io, { Socket } from 'socket.io-client';
import { getConf } from './config';
import version from './version.json';

const WEB_BROKER = "web";

let apiNavigate: any;

interface ErrorI {
    name: string,
    code: number,
    type: string
}

/*
 * Native app to webview authentication
 */
declare const NativeJsInterface: any;

if (typeof NativeJsInterface !== "undefined") {
    const nativeToken: string = NativeJsInterface.getToken();
    const nativeUser: UserI = JSON.parse(NativeJsInterface.getUser());
    set("token", nativeToken);
    set("user", nativeUser);
}
/*
 ***************
 */

export function setNavigate(navigate: Function) {
    apiNavigate = navigate
}

function toQuery(data: any) {

    let params: string = "";

    Object.entries(data)
        .forEach((entry: Array<any>, index: number) => {
            params += (index === 0 ? "?" : "&") + `${entry[0]}=${encodeURIComponent(entry[1])}`;
        });

    return params;
}

export function formDataToJSON(data:FormData) {
    let dataObject:any = {};
    data.forEach((value, key) => {
        let match:any =key.match(/(.+)\[(.+)\]/);
        if(match) {
            if (!Reflect.has(dataObject, match[1])) {
                dataObject[match[1]] = {};
            }
            dataObject[match[1]][match[2]] = value
            return;
        }
        if (!Reflect.has(dataObject, key)) {
            dataObject[key] = value;
            return;
        }
        if (!Array.isArray(dataObject[key])) {
            dataObject[key] = [dataObject[key]];
        }
        dataObject[key].push(value);
    });
    return dataObject;
}

function toJSONString(data:FormData|object) {
    if(data instanceof FormData) {
        return JSON.stringify(formDataToJSON(data));
    } else if (data instanceof Object) {
        return JSON.stringify(data);
    }
}

async function ajax(method: string, endpoint: string, data?:any) {

    let token: string = await get("token");

    return $.ajax({
        method: method,
        url: `${getConf("apiBaseUrl")}${endpoint}`,
        beforeSend: function(request) {
            request.setRequestHeader("authorization", `Bearer ${token}`);
        },
        contentType: "application/json",
        data: data ? toJSONString(data) : {}
    })
        .catch((xhr: any, status: string, name: string) => {
            let error = xhr.responseJSON;
            redirectOnError(token, error, status, name);
            throw error;
        });
}

async function ajaxUpload(method: string, endpoint: string, data:any) {

    let token: string = await get("token");

    return $.ajax({
        method: method,
        url: `${getConf("apiBaseUrl")}${endpoint}`,
        beforeSend: function(request) {
            request.setRequestHeader("authorization", `Bearer ${token}`);
        },
        contentType: false,
        processData: false,
        data: data
    })
        .catch((xhr: any, status: string, name: string) => {
            let error = xhr.responseJSON;
            redirectOnError(token, error, status, name);
            throw error;
        });
}

async function unauthenticatedAjaxUpload(method: string, endpoint: string, data:any) {

    // return fetch(`http://localhost:3000${endpoint}`, {
    //     method: 'POST',
    //     body: data,
    // });
    return $.ajax({
        method: method,
        url: `${getConf("apiBaseUrl")}${endpoint}`,
        contentType: false,
        processData: false,
        data: data
    })
        .catch((xhr: any, status: string, name: string) => {
            let error = xhr.responseJSON;
            redirectOnError("", error, status, name);
            throw error;
        });
}

function redirectOnError(token: string, error: ErrorI, status: string, name: string) {
    if(error.name === "MoleculerError") {
        if(error.code === 401 && token) {
            remove("token");
            remove("user");
            if(apiNavigate) {
                const redirTo = encodeURIComponent(window.location.pathname + window.location.search);
                apiNavigate(`/signin?redir=${redirTo}`);
            } else {
                window.location.href = "/";
            }
        } else if(error.code === 403) {
            if (apiNavigate) {
                apiNavigate("/email_not_verified");
            }
        }
    } else if(error.name !== "ValidationError" && error.name !== "Error") {
        if(apiNavigate) {
            apiNavigate("/error", {state: {
                data: error,
                status: status,
                name: name
            }});
        }
    }
}

export async function isCurrentUser(id: string) {
    const user: UserI = await get("user");
    return user && user.id === id;
}

export function getPlaceByToken(token: string|undefined) {
    return ajax("GET", `/api/public/place/${token}`);
}

export function getCall(callId: string|undefined) {
    return ajax("GET", `/api/calls/${callId}`);
}

export function acceptCall(callId: string|undefined) {
    return ajax("GET", `/api/call/${callId}/answer`);
}

export function rejectCall(callId: string|undefined) {
    return ajax("GET", `/api/call/${callId}/reject`);
}

export function initCall(aliasKey: string|undefined) {
    return ajax("POST", "/api/public/call", {key: aliasKey});
}

export function cancelCall(callId: string) {
    return ajax("GET", `/api/public/call/${callId}/cancel`);
}

export function getCallPollingUrl(callId: string) {
    return `${getConf("apiBaseUrl")}/api/public/call/${callId}/polling`;
}

export function signup(data: any) {

    const payload = data instanceof FormData 
        ? formDataToJSON(data)
        : data;

    Object.assign(payload, {
        clientVersion: version.id
    });

    return ajax("POST", "/api/signup", payload)
        .then((data: {token: string, user:UserI}|{succeeded: boolean}) => {
            if("token" in data) {
                set("token", data.token);
                set("user", data.user);
            }
            return data;
        });
}

export async function signin(data: FormData) {

    let token;
    const payload = formDataToJSON(data);

    try {
        token = await getSubscription();
    } catch(error) {
        console.log("failed to retrieve subscription");
    }

    if(token) {
        Object.assign(payload, {
            pushTokens: [{
                broker: WEB_BROKER,
                type: "web",
                token: token
            }],
            clientVersion: version.id
        });
    }

    return ajax("POST", "/api/login", payload)
        .then((data: {token: string, user:UserI}) => {
            set("token", data.token);
            set("user", data.user);
            return data;
        });
}

export function logout() {
    return ajax("GET", "/api/logout")
        .then(data => {
            remove("token");
            remove("user");
            return data;
        });
}

export function getToken() {
    return get("token");
}

export function setToken(token: string) {
    return set("token", token);
}

export function getUser() {
    return get("user");
}

export function setUser(user: UserI) {
    return set("user", user);
}

export function checkVerifyEmailToken(token: string|undefined) {
    return ajax("GET", `/api/verify_email?token=${token}`)
        .then(async (data: UserI) => {
            const user: UserI = await get("user");
            if(user && user.id === data.id) {
                Object.keys(data).forEach((key: string) => {
                    if(key in user) {
                        user[key] = data[key];
                    }
                });
                set("user", user);
            }
            return data;
        });
}

export function requestPasswordInit(email: string) {
    return ajax("GET", `/api/user/init_password?email=${encodeURIComponent(email)}`);
}

export function requestPasswordReset(email: string) {
    return ajax("GET", `/api/reset_password?email=${encodeURIComponent(email)}`);
}

export function checkNewPasswordToken(token: string|undefined) {
    return ajax("GET", `/api/new_password?token=${token}`);
}

export function setNewPassword(data: any) {
    return ajax("POST", "/api/new_password", data)
        .then(async (data: UserI) => {
            const user: UserI = await get("user");
            if(user && user.id === data.id) {
                Object.keys(data).forEach((key: string) => {
                    if(key in user) {
                        user[key] = data[key];
                    }
                });
                set("user", user);
            }
            return data;
        });
}

export function acceptInvitation(key: string) {
    return ajax("GET", `/api/accept_invitation/${key}`)
}

export function getPlaces(data: PaginationParams|undefined={}) {
    return ajax("GET", `/api/places${toQuery(data)}`);
}

export function getAllPlaces() {
    return ajax("GET", "/api/places/all");
}

export function getUnpairedPlaces(data: PaginationParams|undefined={}) {
    return ajax("GET", `/api/places/unpaired${toQuery(data)}`);
}

export function getFavoritePlaces(data: PaginationParams|undefined={}) {
    return ajax("GET", `/api/places/favorite${toQuery(data)}`);
}

export function getPlace(placeId: string) {
    return ajax("GET", `/api/places/${placeId}`);
}

export function createPlace(data: any) {
    return ajax("POST", "/api/place", data);
}

export function updatePlace(placeId: string, data: any) {
    return ajax("PUT", `/api/places/${placeId}`, data);
}

export function promotePlace(placeId: string) {
    return ajax("PUT", `/api/places/${placeId}/promote`, {});
}

export function resetPlace(placeId: string) {
    return ajax("PUT", `/api/places/${placeId}/reset`);
}

export function deletePlace(placeId: string) {
    return ajax("DELETE", `/api/places/${placeId}`);
}

export function getPlacesCountLog() {
    return ajax("GET", "/api/places/count");
}

export function getCallsLog(data: PaginationParams|undefined={}) {
    return ajax("GET",`/api/calls${toQuery(data)}`);
}

export function countMessages() {
    return ajax("GET","/api/messages/count");
}

export function getMessages(data: PaginationParams|undefined={}) {
    return ajax("GET",`/api/messages${toQuery(data)}`);
}

export function setMessageAsSeen(messageId: string, seen:boolean) {
    return ajax("PUT",`/api/messages/${messageId}`, {seen});
}

export function deleteMessage(messageId: string) {
    return ajax("DELETE",`/api/messages/${messageId}`);
}

export function getInternalMessages(data: PaginationParams|undefined={}) {
    return ajax("GET", `/api/internalMessages${toQuery(data)}`);
}

export function countUnseenInternalMessages() {
    return ajax("GET","/api/internalMessages/unseen");
}

export function getInternalMessage(messageId:string) {
    return ajax("GET",`/api/internalMessages/${messageId}`);
}

export function deleteInternalMessage(messageId:string) {
    return ajax("DELETE",`/api/internalMessages/${messageId}`);
}

export function getElocode(placeId: string, elocodeId: string) {
    return ajax("GET", `/api/places/${placeId}/elocodes/${elocodeId}`);
}

export function updateElocode(placeId: string, elocodeId: string, data: object) {
    return ajax("PUT", `/api/places/${placeId}/elocodes/${elocodeId}`, {data: data});
}

export function getQrCode(placeId: string|undefined, groupId: string|undefined, elocodeId: string, dotColor?: string) {

    const queryParams = toQuery({
        type: "dataurl",
        dotcolor: dotColor || "#3fa535"
    })

    if(groupId) {
        return ajax("GET", `/api/places/${placeId}/groups/${groupId}/elocodes/${elocodeId}/qr-code${queryParams}`);
    } else {
        return ajax("GET", `/api/places/${placeId}/elocodes/${elocodeId}/qr-code${queryParams}`);
    }
}

export function sendQrCode(elocodeId: string) {
    return ajax("GET", `/api/elocodes/${elocodeId}/send`);
}

export function setPlaceNotify(placeId: string, state: boolean ) {
    return ajax("PUT", `/api/places/${placeId}/notify`, {notify: state});
}

export function setPlaceFavorite(placeId: string, state: boolean ) {
    return ajax("PUT", `/api/places/${placeId}/favorite`, {favorite: state});
}

export function sendInvitation(placeId: string, data: {email: string}) {
    return ajax("POST", `/api/places/${placeId}/invitation`, data);
}

export function resendInvitation(placeId: string, invitationId: string) {
    return ajax("GET", `/api/places/${placeId}/invitation/${invitationId}/resend`);
}

export function cancelInvitation(placeId: string, invitationId: string) {
    return ajax("DELETE", `/api/places/${placeId}/invitation/${invitationId}`);
}

export function setResidentRole(placeId: string, memberId: string, isAdmin: boolean) {
    return ajax("PUT", `/api/places/${placeId}/members/${memberId}`, {role: isAdmin ? "admin" : "user" });
}

export function deleteResident(placeId: string, memberId: string) {
    return ajax("DELETE", `/api/places/${placeId}/members/${memberId}`);
}

export function removeResident(placeId: string) {
    return ajax("DELETE", `/api/places/${placeId}/member`);
}

export function createGroup(placeId: string, data: {name: string}) {
    return ajax("POST", `/api/places/${placeId}/group`, data);
}

export function getGroup(placeId: string, groupId: string) {
    return ajax("GET", `/api/places/${placeId}/groups/${groupId}`);
}

export function updateGroup(placeId: string, groupId: string, data: {name: string}) {
    return ajax("PUT", `/api/places/${placeId}/groups/${groupId}`, data);
}

export function resetGroup(placeId: string, groupId: string) {
    return ajax("PUT", `/api/places/${placeId}/groups/${groupId}/reset`);
}

export function deleteGroup(placeId: string, groupId: string) {
    return ajax("DELETE", `/api/places/${placeId}/groups/${groupId}`);
}

export function addGroupMember(placeId: string, groupId: string, data: any) {
    return ajax("POST", `/api/places/${placeId}/groups/${groupId}/member`, data);
}

export function deleteGroupMember(placeId: string, groupId: string, memberId: string) {
    return ajax("DELETE", `/api/places/${placeId}/groups/${groupId}/members/${memberId}`);
}

export function setGroupNotify(placeId: string, groupId: string, state: boolean ) {
    return ajax("PUT", `/api/places/${placeId}/groups/${groupId}/notify`, {notify: state});
}

export function resendEmailVerification(data: {email: string}) {
    return ajax("POST", "/api/user/verify_email", data);
}

export function getFreshUser() {
    return ajax("GET", "/api/user")
        .then(async (user: UserI) => {
            user.picture = `${user.picture}?s=${new Date().getTime()}`;
            set("user", user);
            return user;
        });
}

export function updateUser(data: {firstname: string, lastname: string, username: string, lang: string, currency: string, country:string}) {
    return ajax("PUT", "/api/user", data)
        .then(async (data: UserI) => {
            const user: UserI = await get("user");
            Object.keys(data).forEach((key: string) => {
                //if(key in user) {
                    if(key === "picture") {
                        user[key] = data.picture ? `${data.picture}?s=${new Date().getTime()}` : '';
                    } else {
                        user[key] = data[key];
                    }
                //}
            });
            set("user", user);
            return user;
        });
}

export function updatePassword(data: {old_password: string, new_password: string, new_password_confirm: string}) {
    return ajax("PUT", "/api/user/password", data)
        .then(async () => {
            return data;
        });
}

export function updateRecovery(data: {actual_recovery?: string, recovery?: string, password?: string}) {
    return ajax("PUT", "/api/user/recovery", data)
        .then(async (data: UserI) => {
            if(data.recoveryEmail || data.recoveryPhone) {
                set("user", data);
            }
            return data;
        });
}

export function uploadUserPic(data: any) {
    return ajaxUpload("PUT", "/api/user/picture", data)
        .then(async (data: any) => {
            const user: UserI = await get("user");
            const picture = `${data[0].picture}?s=${new Date().getTime()}`;
            user.picture = picture;
            set("user", user);
            return { picture: picture };
        });
}

export function visitorSendMessage(data: any) {
    return unauthenticatedAjaxUpload("POST", "/api/public/message", data)
}

export function deleteUserPic() {
    return ajax("DELETE", "/api/user/picture");
}

export function deleteUser() {
    return ajax("DELETE", `/api/user`)
        .then((data: any) => {
            remove("token");
            remove("user");
            return data;
        });
}

export function hasNewSmartlockAccount() {
    return ajax("GET", "/api/smartlocks/new_accounts");
}

export function getSettings() {
    return ajax("GET", "/api/settings");
}

export function hasFeature(name: string) {
    return ajax("GET", `/api/features/${name}`)
        .then( (data:any) => {
            return !!data?.hasFeature
        })
}

export function updateSettings(data: any) {
    return ajax("PUT", "/api/settings", data)
        .then(async ({data}: {data: SettingsI}) => {
            const user: UserI = await get("user");
            Object.keys(data).forEach((key: string) => {
                if(key in user.settings) {
                    user.settings[key] = data[key];
                }
            });
            set("user", user);
            return user;
        })
}

export function addWebPushToken(token: string) {
    return ajax("POST", "/api/push_tokens", {
        pushTokens: [{
            broker: WEB_BROKER,
            type: "web",
            token: token
        }]
    });
}

export function removeWebPushToken(token: string) {
    return ajax("DELETE", `/api/push_tokens`, {token: token});
}

export function getAvailableElocode() {
    return ajax("GET", "/api/elocode");
}

export function pairElocode(placeId: string, data: {key: string}) {
    return ajax("POST", `/api/places/${placeId}/elocode`, data);
}

export function checkElocode(key: string) {
    return ajax("GET", `/api/elocode/check?key=${key}`);
}

export function createTicket(data: {subject: string, body: string, tags: Array<string>}) {
    return ajax("POST", "/api/ticket", data);
}

export function getProducts(type: "plan" | "product") {
    return ajax("GET", `/api/products?type=${type}`);
}

export function getProduct(id: string) {
    return ajax("GET", `/api/products/${id}`);
}

export function getCustomer() {
    return ajax("GET", "/api/customer");
}

export function createPaymentIntent(data: {items: {id: string, quantity: number}[], currency: string, meta: {[key: string]: any}}) {
    return ajax("POST", "/api/payment_intent", data);
}

export function confirmPaymentIntent(data: {paymentIntentId: string, paymentMethodId: string}) {
    return ajax("POST", "/api/confirm_payment_intent", data);
}

export function createPaymentMethod() {
    return ajax("POST", "/api/payment_mean", {});
}

export function updatePaymentMethod(data: {customerId: string, oldPaymentMethodId: string | undefined, paymentMethodId: string}) {
    return ajax("PUT", "/api/payment_mean", data);
}

export function getPaymentMethods() {
    return ajax("GET", "/api/payment_means");
}

export function setSubscription(data: {productId: string, priceId: string, couponCode: string | undefined, currency: string, customerId: string, paymentMethodId?: string, downgrade: boolean}) {
    return ajax("POST", "/api/subscription", data);
}

export function cancelSubscription(subscriptionId: string) {
    return ajax("DELETE", `/api/subscriptions/${subscriptionId}`);
}

export function checkCoupon(code: string) {
    return ajax("GET", `/api/coupons/${code}`);
}

export function getUserSubscription() {
    return ajax("GET", "/api/subscription");
}

export function getUserSubscriptionStatus() {
    return ajax("GET", "/api/subscription/status");
}

export function getInvoices() {
    return ajax("GET", "/api/invoices");
}

export function getLatestInvoice() {
    return ajax("GET", "/api/invoices/latest");
}

export function getUpcomingInvoice() {
    return ajax("GET", "/api/invoices/upcoming");
}

export function simulateUpcomingInvoice(priceId: string, couponCode: string|undefined) {
    let params = `?priceId=${priceId}`;
    if(couponCode) {
        params +=  `&couponCode=${couponCode}`;
    }
    return ajax("GET", `/api/invoices/simulate${params}`);
}

export function bindAccount(data?: object) {
    return ajax("POST", "/api/smartlocks/account", data);
}

export function unbindAccount(accountId: string) {
    return ajax("DELETE", `/api/smartlocks/accounts/${accountId}`);
}

export function bindSmartLock(placeId: string, data: {deviceId: string, name: string}) {
    return ajax("POST", `/api/places/${placeId}/lock`, data);
}

export function unbindSmartLock(placeId: string, lockId: string) {
    return ajax("DELETE", `/api/places/${placeId}/locks/${lockId}`);
}

export function bindRelay(placeId: string, data: {relayId: string}) {
    return ajax("POST", `/api/places/${placeId}/relay`, data);
}

export function unbindRelay(placeId: string, relayId: string) {
    return ajax("DELETE", `/api/places/${placeId}/relay/${relayId}`);
}

export function listPlaceSmartLocks(placeId: string) {
    return ajax("GET", `/api/places/${placeId}/smartlocks/devices`);
}

export function getPlaceSmartLock(placeId: string, deviceId: string) {
    return ajax("GET", `/api/places/${placeId}/smartlocks/devices/${deviceId}`);
}

export function getPlaceSmartLockStatus(placeId: string, deviceId: string) {
    return ajax("GET", `/api/places/${placeId}/smartlocks/devices/${deviceId}`);
}

export function lockPlaceSmartLock(placeId: string, deviceId: string) {
    return ajax("PUT", `/api/places/${placeId}/smartlocks/devices/${deviceId}/lock`);
}

export function unlockPlaceSmartLock(placeId: string, deviceId: string) {
    return ajax("PUT", `/api/places/${placeId}/smartlocks/devices/${deviceId}/unlock`);
}

export function getPlaceSmartLockAttempt(placeId: string, attemptId: string) {
    return ajax("GET", `/api/places/${placeId}/smartlocks/attempt/${attemptId}`);
}

export function addRelay() {
    return ajax("POST", `/api/relay`);
}
export function pairingRelay(relayId: string) {
    return ajax("POST", `/api/relays/:id/pairing`);
}
export function getRelay(relayId: string) {
    return ajax("GET", `/api/relays/${relayId}`);
}
export function getRelays() {
    return ajax("GET", `/api/relays`);
}
export function removeRelay(relayId: string) {
    return ajax("DELETE", `/api/relays/${relayId}`);
}
export function activateRelay(relayId: string) {
    return ajax("POST", `/api/relays/${relayId}/activate`);
}
export function deactivateRelay(relayId: string) {
    return ajax("POST", `/api/relays/${relayId}/deactivate`);
}
export function updateRelay(relayId: string, data:any) {
    return ajax("PUT", `/api/relays/${relayId}`, data);
}

export function pairHellobject(key: string, data: {placeId: string}) {
    return ajax("PUT", `/api/elocodes/pair/${key}`, data);
}

export function pairableHellobject(key: string) {
    return (async () => {
        const user: UserI = await get("user");
        return ajax("GET", user
            ? `/api/pairable/${key}`
            : `/api/public/pairable/${key}`);
    })();
}

export function getCatalog(data: PaginationParams|undefined={}) {
    return ajax("GET", `/api/catalog${toQuery(data)}`);
}

export function getCatalogProduct(id: string) {
    return ajax("GET", `/api/catalog/${id}`);
}

export function createOrder(data: any) {
    return ajax("POST", "/api/order", data);
}

export function updateOrder(orderId: string, data: any) {
    return ajax("PUT", `/api/orders/${orderId}`, data);
}

export function getOrders(data: PaginationParams|undefined={}) {
    return ajax("GET", `/api/orders${toQuery(data)}`);
}

export function getOrder(orderId: string) {
    return ajax("GET", `/api/orders/${orderId}`);
}

export function deleteOrder(orderId: string) {
    return ajax("DELETE", `/api/orders/${orderId}`);
}

export function checkConnectionToken(id: string, token: string) {
    return ajax("GET", `/api/connect_user?id=${id}&token=${token}`);
}

export function checkUpdateIdentifierToken(token: string|undefined) {
    return ajax("GET", `/api/update_identifier?token=${token}`)
        .then(async (data: UserI) => {
            const user: UserI = await get("user");
            if(user && user.id === data.id) {
                Object.keys(data).forEach((key: string) => {
                    if(key in user) {
                        user[key] = data[key];
                    }
                });
                set("user", user);
            }
            return data;
        });
}

/*###################################
 *
 ###################################*/

interface SigninLinkProps {
    email: string
    onPending: (data: any) => void
    onSucceeded: (data: any) => void
    onFailed: (data: any) => void
    onError: (data: any) => void
}

export class SigninLink {

    key: string|null;
    socket: Socket|null;
    _onPending: (data: any) => void;
    _onSucceeded: (data: any) => void;
    _onFailed: (data: any) => void;
    _onError: (data: any) => void;

    constructor({email, onPending, onSucceeded, onFailed, onError}: SigninLinkProps) {

        this.key = null;
        this.socket = null;
        this._onPending = onPending;
        this._onSucceeded = onSucceeded;
        this._onFailed = onFailed;
        this._onError = onError;

        (async () => {

            let token;
            let payload = {
                email,
                clientVersion: version.id
            };

            try {
                token = await getSubscription();
            } catch(error) {
                console.log("failed to retrieve subscription");
            }

            if(token) {
                Object.assign(payload, {pushTokens: [{
                    broker: WEB_BROKER,
                    type: "web",
                    token: token
                }]});
            }

            const data: any = await ajax("POST", "/api/login_link", payload);
            const clearRequest = () => ajax("DELETE", `/api/login_link?key=${this.key}`);

            this.key = data.key;
            this.socket = io(`${getConf("wsBaseUrl")}/signin`, {
                'query': {key: this.key},
                'transports': ['websocket'],
                'forceNew': true,
                'reconnection': true,
                'reconnectionDelay': 200,
                'reconnectionAttempts': 10
            });

            this.socket?.on("connect", async () => {
                this.socket?.emit('signin', 'joinRoom', {key:this.key});
            });

            this.socket?.on("connect_error", (error) => {
                console.log(`connect_error due to ${error.message}`);
            });

            this.socket?.on("disconnect", (reason) => {
                if (reason === "io server disconnect") {
                    this.socket?.connect();
                } else if(reason === "io client disconnect") {
                    this.socket?.removeAllListeners();
                    clearRequest()
                        .then(() => {});
                }
            });

            this.socket?.on("signin.pending", (data: any) => {
                this._onPending(data);
            });

            this.socket?.on("signin.succeeded", (data: any) => {
                this.socket?.disconnect();
                set("token", data.token);
                set("user", data.user);
                this._onSucceeded(data);
            });

            this.socket?.on("signin.failed", (data: any) => {
                this.socket?.disconnect();
                this._onFailed(data);
            });

        })()
            .catch((error) => {
                this._onError(error);
            });
    }

    cancel() {
        this.socket?.disconnect();
        this.key = null;
        this.socket = null;
    }
}

interface RelayLinkProps {
    relayId: string
    onEvent: (name: string, data: any) => void
    onError: (data: any) => void
}

export class RelaySocket {
    socket: Socket|null;
    relayId: string;
    _onEvent: (name: string, data: any) => void;
    _onError: (data: any) => void;

    constructor({relayId, onEvent, onError}: RelayLinkProps) {

        this.socket = null;
        this._onEvent = onEvent;
        this._onError = onError;
        this.relayId = relayId;

        (async () => {
            const socketPath = `${getConf("wsBaseUrl")}/resident`;
            let token: string = await get("token");

            this.socket = io(socketPath, {
                'transports': ['websocket'],
                'forceNew': true,
                'reconnection': true,
                'reconnectionDelay': 200,
                'reconnectionAttempts': 10,
                'query': token ? {
                    token: token,
                    room: relayId
            } : {}
            });

            this.socket?.on("connect", async () => {

                this.socket?.emit('relay', 'status', {id:this.relayId}, (error: any, data: {succeeded: boolean}) => {
                    if(error) {
                        console.log("Error", error);
                        return;
                    }

                    this.onEvent("status", data);
                })
                this.onEvent("connected", {});
            });

            this.socket?.on("connect_error", (error) => {
                console.log(`connect_error due to ${error.message}`);
            });

            this.socket?.on("disconnect", (reason) => {
                if (reason === "io server disconnect") {
                    this.socket?.connect();
                } else if(reason === "io client disconnect") {
                    this.socket?.removeAllListeners();
                }
            });

            this.socket?.on("relay.paired", (data: any) => { this.onEvent("paired", data); })
            this.socket?.on("relay.status", (data: any) => { this.onEvent("status", data); })
            this.socket?.on("relay.rssi", (data: any) => { this.onEvent("rssi", data); })
        })()
            .catch((error) => {
                this._onError(error);
            });
    }

    onEvent(eventName:string, data:any) {
        this._onEvent(eventName, data)
    }

    needStatus() {
        this.socket?.emit('relay', 'status', {id:this.relayId})
    }

    needRssi() {
        this.socket?.emit('relay', 'rssi', {id:this.relayId})
    }

    cancel() {
        this.socket?.disconnect();
        this.socket = null;
    }

}