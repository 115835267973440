import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getParam } from '../../urlUtils';
import { getMessages, setMessageAsSeen, deleteMessage } from '../../api'
import moment from 'moment';
import { capitalizeAll } from '../../capitalize'
import Spinner from '../element/spinner';
import Pagination from '../element/pagination';
import EmptyList from '../element/emptyList';
import { PaginatedList, MessageI } from '../../interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft, faImagePortrait, faTrash, faVoicemail } from '@fortawesome/free-solid-svg-icons';
import { createNotifyModal, createConfirmModal } from '../element/modal';
import { AppContext } from '../../context';


export interface MessageListItemProps {
    list:Array<MessageI>,
    noItemLines: number,
    noItemMessage: string,
    handleNeedRefresh: () => void
}

export async function showMessageModal(message:MessageI, translation:any, seenHandler?:(messageId:string)=>void, deleteHandler?:(messageId:string)=>void) {
    const t = translation

    createNotifyModal(
        t("message_view_modal.title", "Message"),
        <div>
            <p>
                <b>{capitalizeAll(message.place.name)}</b> {moment(message.createdAt).format(t("global.short_date_format_hours", "DD/MM HH:mm") as string)}
            </p>
            <p>
                { t("message_view_modal.text","Un visiteur à essayé de vous joindre et vous a laissé ce message:") }
            </p>
            { message.text && <p className="text"> {message.text} </p> }
            { (message.photo) && <img src={message.photo} alt="photo" className="img-fluid border border-secondary mb-3"/> }
            { (message.audio) && <audio controls className="rounded" src={message.audio} /> }
            <div className="modal-footer">
                { deleteHandler &&
                    <button className="btn btn-sm btn-primary" data-bs-toggle="modal" style={{marginRight: "-16px"}}
                        onClick={ ()=>{ deleteHandler && deleteHandler(message.id) } }
                    >
                        <FontAwesomeIcon icon={faTrash} className="me-2"/>
                        {t("place_view.button.delete", "Supprimer")}
                    </button>
                }
            </div>
        </div>,
        t("message_view_modal.button.close", "Fermer") as string
    )

    await setMessageAsSeen(message.id,true)
    seenHandler && seenHandler(message.id)

}
export function MessagesListItem({ list, noItemLines, noItemMessage, handleNeedRefresh }: MessageListItemProps) {

    const { t } = useTranslation('main');
    const [messages, setMessages] = useState<Array<MessageI>>(list);
    const { setNeedRefreshNewMessageCount } = useContext(AppContext);

    useEffect(() => {
        setMessages(list)
    }, [list]);

    async function confirmeDeleteMessage(messageId:string) {
        const confirm = await createConfirmModal(t("message_view_delete_modal.title", "Supprimer"),
            <p>{t("message_view_delete_modal.message", "Confirmez-vous vouloir supprimer ce message ?")}</p>,
            t("message_view_delete_modal.button.confirm", "Confirmer")
        )

        if(!confirm) {
            return;
        }

        await deleteMessage(messageId)
        handleNeedRefresh()
    }

    function flaggedAsSeen(messageId:string) {
        try{
            setNeedRefreshNewMessageCount( true )
            // Update message list with message flag as seen
            setMessages(messages?.map(item=>{
                if ( item.id === messageId ) {
                    item.seen = true
                }
                return item
            }))
        } catch (_){}
    }

    async function showMessage(message:MessageI) {
        return showMessageModal( message, t, flaggedAsSeen, confirmeDeleteMessage )
    }

    if(!messages.length) {
        return <EmptyList lines={noItemLines || 3} message={noItemMessage} />;
    }

    return (
        <ul className="list-group w-100" >
            {messages.map((message:MessageI) =>
                <li key={message.id} className="list-group-item" onClick={()=>showMessage(message)}>
                    <div className={`d-flex flex-row align-items-center ${message.seen?'':'fw-bold'}`}>
                        <span className='me-2'>{capitalizeAll(message.place.name)} {message.group ? `- ${capitalizeAll(message.group.name)}` : "" }</span>
                        { message.photo && <FontAwesomeIcon icon={faImagePortrait} className='me-2'/>}
                        { message.audio && <FontAwesomeIcon icon={faVoicemail}     className='me-2'/>}
                        { message.text  && <FontAwesomeIcon icon={faAlignLeft}     className='me-2'/>}
                        <div className="flex-fill text-end">
                            {moment(message.createdAt).format(t("global.short_date_format_hours", "DD/MM HH:mm") as string)}
                        </div>
                    </div>
                </li>
            )}
        </ul>
    )
}
interface MessagesPaginatedListProps extends PaginatedList<MessageI> {
    handleNeedRefresh:()=>void
}

function MessagesPaginatedList({rows, page, totalPages, handleNeedRefresh}:MessagesPaginatedListProps) {

    const { t } = useTranslation('main');

    return (
        <React.Fragment>
            <MessagesListItem
                list={rows}
                noItemLines={9}
                noItemMessage={t("main:messages.list.no_item", "Aucun message")}
                handleNeedRefresh={() => {
                    handleNeedRefresh()
                }}
            />
            {totalPages > 1
                ? <Pagination page={page} totalPages={totalPages} />
                : null}
        </React.Fragment>
    );
}

function MessagesList() {
    const { setNeedRefreshNewMessageCount, newMessageCount } = useContext(AppContext);

    const { t } = useTranslation('main');
    const [messageList, setMessageList] = useState<PaginatedList<MessageI>>();
    const [ messageCount, setMessageCount ] = useState<number>(newMessageCount);
    const [searchParams] = useSearchParams();
    const page = getParam(searchParams, "page");

    function refresh() {
        let params = page && !isNaN(page as any)
            ? {page: Number(page)}
            : undefined;
        getMessages(params)
            .then((data: PaginatedList<MessageI>) => {
                setMessageList(data);
                setNeedRefreshNewMessageCount( true )
            })
    }

    useEffect(() => {
        refresh()
    }, [page]);

    useEffect(() => {
        if(newMessageCount > messageCount){
            refresh();
        }
        setMessageCount(newMessageCount);
    }, [newMessageCount]);

    if (messageList === undefined) {
        return <Spinner />;
    }

    return (
        <main className="flex-grow-1 dashboard">
            <h3 className="flex-grow-1 text-capitalize mt-3 mb-3">
                {t("messages.page.title", "Messages reçus")}
            </h3>
            <MessagesPaginatedList {...messageList} handleNeedRefresh={() =>{refresh()}}/>
        </main>
    );

}

export default MessagesList;